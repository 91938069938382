var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "object-selection-overlay"
    },
    on: {
      "click": function ($event) {
        return _vm.closePopover($event);
      }
    }
  }, [_c('div', {
    staticClass: "card-details",
    attrs: {
      "id": "popover"
    }
  }, [_c('div', {
    staticClass: "object-rows"
  }, _vm._l(_vm.project.objects, function (object) {
    return _c('div', {
      key: object._id,
      staticClass: "object-row",
      on: {
        "click": function ($event) {
          return _vm.selectObject(object);
        }
      }
    }, [_c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(object.address))])]);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }