var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main",
    on: {
      "focusin": function ($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "background": "white"
    },
    attrs: {
      "autocomplete": "off",
      "placeholder": _vm.placeholder,
      "error": _vm.errorKey,
      "outlined": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "click": _vm.resetPage,
      "focus": _vm.onFocus,
      "blur": _vm.resetPage
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.isOpen ? _c('div', {
    staticClass: "items"
  }, [_c('div', {
    ref: "scrollContainer",
    staticClass: "item-wrapper"
  }, [_vm._l(_vm.foundItems, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      attrs: {
        "id": 'item' + item._id
      },
      on: {
        "mousedown": function ($event) {
          $event.preventDefault();
          return _vm.chooseResult(item[_vm.displayKey], item);
        }
      }
    }, [_vm._v(" " + _vm._s(item[_vm.displayKey]) + " ")]);
  }), _vm.isFetching ? _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "80px",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e()], 2), _vm.search && _vm.foundItems.length === 0 && !_vm.searchInProgress ? _c('div', {
    staticClass: "nothing-found"
  }, [_c('div', {
    staticClass: "item no-hover"
  }, [_vm._v(_vm._s(_vm.$t("NoResultsFound")) + "\"" + _vm._s(_vm.search) + "\"")])]) : _vm._e(), _c('div', {
    staticClass: "separator"
  }), _c('div', {
    staticClass: "add-new",
    on: {
      "mousedown": function ($event) {
        $event.preventDefault();
        return _vm.addNew.apply(null, arguments);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plusSign.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("AddNew")) + " ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }