var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: 'listing-add-' + _vm.updateHTML
  }, [_vm.objectSelectionOpen && _vm.chosenLinkedDeal.objects && _vm.chosenLinkedDeal.objects.length > 0 ? _c('ObjectSelection', {
    attrs: {
      "project": _vm.chosenLinkedDeal,
      "position": _vm.position
    },
    on: {
      "closed": function ($event) {
        _vm.objectSelectionOpen = false;
      },
      "objectSelected": _vm.chooseObject
    }
  }) : _vm._e(), _vm.projectAddOpen ? _c('Sidepanel', {
    staticStyle: {
      "z-index": "11"
    },
    attrs: {
      "no-refresh": true,
      "from-add-listing": true
    },
    on: {
      "saveResponse": _vm.handleNewDeal,
      "closed": _vm.closeProjectAdd
    }
  }) : _vm._e(), _c('div', {
    staticClass: "sticky",
    staticStyle: {
      "display": "flex",
      "height": "64px",
      "width": "100%",
      "z-index": "10",
      "padding-left": "156px",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "add-button",
    staticStyle: {
      "margin-right": "12px"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, true);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/checkmarkCircled.svg")
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("SaveAndExit")))]) : _vm._e()]), _c('div', {
    staticClass: "cancel",
    staticStyle: {
      "margin-right": "12px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, false);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("Save")))]) : _vm._e()]), _c('button', {
    staticClass: "cancel padding-top",
    staticStyle: {
      "margin-right": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _vm.edit ? _c('div', {
    staticStyle: {
      "margin-right": "16px"
    }
  }, [_vm._v("•")]) : _vm._e(), _vm.edit ? _c('div', [_c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.openDeleteModal
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")])]) : _vm._e()]), _c('div', {
    staticClass: "header",
    staticStyle: {
      "margin-top": "129px",
      "padding-top": "0"
    }
  }, [!_vm.edit ? _c('h3', [_vm._v(_vm._s(_vm.$t("AddNewListing")))]) : _vm._e(), _vm.edit ? _c('h3', [_vm._v(_vm._s(_vm.$t("EditListing")))]) : _vm._e(), _c('p', {
    staticClass: "statusLabel content-bold",
    class: {
      clickable: _vm.edit,
      'draft-label ': _vm.form.status === 'draft',
      'active-label ': _vm.form.status === 'active',
      'booked-label': _vm.form.status === 'booked',
      'sold-label ': _vm.form.status === 'sold',
      'archived-label': _vm.form.status === 'archived',
      'rented-label': _vm.form.status === 'rented',
      'expired-label': _vm.form.status === 'expired'
    },
    attrs: {
      "id": "statusLabel"
    },
    on: {
      "click": function ($event) {
        return _vm.openStatusDropdown(_vm.form);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.status(_vm.form.status)) + " ")]), _c('v-tooltip', {
    key: _vm.bookingUpdater,
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm.form.status === 'booked' ? _c('p', _vm._g(_vm._b({
          staticClass: "booked-label booked-info-label"
        }, 'p', attrs, false), on), [_c('img', {
          staticStyle: {
            "filter": "invert(1)"
          },
          attrs: {
            "src": require("../../assets/images/info.svg"),
            "alt": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openBookingDetailsModal(_vm.form);
            }
          }
        })]) : _vm._e()];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("BookingDetails")))])]), _vm.form.status === 'active' && _vm.form.dateAvailable && !_vm.form.activeUntil ? _c('p', {
    staticClass: "content-small",
    staticStyle: {
      "margin-left": "8px",
      "cursor": "pointer"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("available")) + " " + _vm._s(_vm.formatDate(_vm.form.dateAvailable)) + " ")]) : _vm._e(), _vm.form.status === 'booked' && _vm.form.bookedUntil ? _c('p', {
    staticClass: "content-small booking-until-date",
    staticStyle: {
      "margin-left": "8px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.openBookingDetailsModal(_vm.form);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.formatDate(_vm.form.bookedUntil)) + " ")]) : _vm._e(), _vm.form.status === 'active' && _vm.form.activeUntil ? _c('date-pick', {
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "isDateDisabled": _vm.isFutureDate
    },
    on: {
      "input": function ($event) {
        return _vm.changeActiveUntil(_vm.form, $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var toggle = _ref2.toggle;
        return [_vm.form.status === 'active' && _vm.form.activeUntil ? _c('p', {
          staticClass: "content-small",
          staticStyle: {
            "margin-left": "8px",
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.form.activeUntil) + " ")]) : _vm._e()];
      }
    }], null, false, 1552725545)
  }) : _vm._e()], 1), _c('div', {
    staticClass: "content"
  }, [!_vm.user.isItaly ? _c('div', {
    staticStyle: {
      "display": "flex",
      "max-height": "48px",
      "height": "48px",
      "background": "white",
      "margin-bottom": "32px",
      "max-width": "960px",
      "border-radius": "8px",
      "padding": "8px 16px 0 16px"
    }
  }, _vm._l(_vm.activeMarketplaces, function (amount, name, index) {
    return _c('div', {
      key: name,
      staticClass: "active-marketplaces",
      staticStyle: {
        "display": "flex"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "color": "black",
        "disabled": _vm.form.addressType === 'generic' && name !== 'www' || _vm.user.companyId === 'S76LvflRF766oIt1NbvS' && name === 'kv'
      },
      model: {
        value: _vm.form.marketplaces.find(function (marketplace) {
          return marketplace.name === name;
        }).isActive,
        callback: function ($$v) {
          _vm.$set(_vm.form.marketplaces.find(function (marketplace) {
            return marketplace.name === name;
          }), "isActive", $$v);
        },
        expression: "\n            form.marketplaces.find((marketplace) => marketplace.name === name)\n              .isActive\n          "
      }
    }), _c('div', {
      staticStyle: {
        "padding-top": "6px"
      }
    }, [_c('span', {
      staticStyle: {
        "padding-right": "4px"
      }
    }, [_vm._v(_vm._s(name))]), _c('span', {
      staticClass: "content-bold"
    }, [_vm._v(_vm._s(amount.active))]), _c('span', [_vm._v("/ " + _vm._s(amount.total))])]), index != Object.keys(_vm.activeMarketplaces).length - 1 ? _c('div', {
      staticStyle: {
        "margin-left": "16px",
        "margin-right": "16px",
        "padding-top": "4px"
      }
    }, [_vm._v(" • ")]) : _vm._e()], 1);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "section-row"
  }, [_c('v-row', {
    staticClass: "subheading"
  }, [_c('div', {
    staticClass: "circle-number",
    staticStyle: {
      "padding-top": "1px"
    }
  }, [_vm._v("1")]), _c('h5', [_vm._v(_vm._s(_vm.$t("AddProjectAndObjectDetails")))])]), _vm.edit && _vm.deal || _vm.deal && _vm.addToProject ? _c('v-row', {
    staticClass: "autocomplete-frame",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "deal-name"
  }, [_vm._v(" " + _vm._s(_vm.deal.name) + " ")]), _c('v-row', {
    staticClass: "deal-name-item",
    on: {
      "click": function ($event) {
        return _vm.openDeal(_vm.deal);
      }
    }
  }, [_vm._v("View")])], 1) : _vm._e(), !_vm.edit && !_vm.addToProject ? _c('v-row', {
    staticClass: "autocomplete-frame",
    staticStyle: {
      "width": "100%"
    }
  }, [_vm.$route.params.referenceDealId && _vm.$route.params.project._id ? _c('v-row', {
    staticClass: "deal-name"
  }, [_vm._v(" " + _vm._s(_vm.chosenLinkedDealName) + " ")]) : _vm._e(), !_vm.$route.params.referenceDealId && !_vm.user.config ? _c('v-row', {
    staticClass: "autocomplete-row"
  }, [_c('v-row', {
    staticClass: "required"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/projects.svg"),
      "alt": ""
    }
  }), _c('h6', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t("AddProject")))])]), _c('v-row', {
    staticStyle: {
      "justify-content": "flex-start",
      "align-items": "center",
      "gap": "32px",
      "margin-top": "16px"
    }
  }, [_vm.dealsLoaded ? _c('Autocomplete', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeMenu,
      expression: "closeMenu"
    }],
    class: 'autocomplete-item ' + (_vm.isErrorDeal ? 'not-valid' : ''),
    attrs: {
      "placeholder": _vm.$t('AddProject'),
      "items": _vm.deals,
      "search-key": "name",
      "display-key": "name",
      "close-menu": _vm.closeDealMenu,
      "written-text": _vm.chosenLinkedDealName,
      "error-key": _vm.isErrorDeal
    },
    on: {
      "resultChosen": _vm.setRelatedDeal,
      "addNew": _vm.openProjectAdd,
      "removeResult": _vm.clearDeal
    }
  }) : _vm._e(), _vm.chosenLinkedDeal ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "flex-start",
      "gap": "4px",
      "align-items": "center"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("ProjectType")) + ":")]), _c('p', {
    class: {
      'content-small-green green-font': _vm.chosenLinkedDeal.dealType == 'Sale',
      'content-small-pink pink-font': _vm.chosenLinkedDeal.dealType == 'Lease out' || _vm.chosenLinkedDeal.dealType == 'LeaseOut'
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDealType()) + " ")])]) : _vm._e()], 1), _vm.isErrorDeal ? _c('v-row', {
    staticClass: "error-text",
    staticStyle: {
      "margin-top": "8px"
    },
    model: {
      value: _vm.isErrorDeal,
      callback: function ($$v) {
        _vm.isErrorDeal = $$v;
      },
      expression: "isErrorDeal"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.user.config ? _c('v-row', {
    staticClass: "required",
    staticStyle: {
      "width": "408px"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/objects.svg"),
      "alt": ""
    }
  }), _c('h6', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t("AddObject")))]), _c('v-row', {
    staticClass: "autocomplete-frame-address",
    staticStyle: {
      "width": "100%",
      "align-items": "center",
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "object-address-container"
  }, [_c('div', {
    staticClass: "address-div",
    class: {
      'address-div': _vm.isErrorAddress === false,
      'address-div gray-border': _vm.isErrorAddress === true
    },
    attrs: {
      "id": "InAadressDiv"
    },
    on: {
      "click": _vm.focusInput,
      "focusout": _vm.unfocusInput,
      "keypress": function ($event) {
        _vm.objectSelectionOpen = false;
      }
    }
  })])])], 1) : _vm._e()], 1) : _vm._e(), _vm.user.config && _vm.addressSelected ? _c('v-row', {
    staticClass: "subheading required"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ListingType")))])]) : _vm._e(), _vm.addressSelected || _vm.edit ? _c('v-row', {
    staticClass: "box-wrapper"
  }, _vm._l(_vm.dealTypes, function (dealType) {
    return _c('button', {
      key: dealType.name,
      staticClass: "big-box1 icon-box",
      class: {
        'big-box1 icon-box': !dealType.isChosen,
        'big-box1-white icon-box active-box': dealType.isChosen
      },
      attrs: {
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          _vm.form.dealType = dealType.value;
          _vm.chooseBox1(dealType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [_c('img', {
      staticClass: "big-box3-image",
      class: {
        'big-box2-image': !dealType.isChosen,
        'big-box2-image-white ': dealType.isChosen
      },
      attrs: {
        "src": require('../../assets/images/' + dealType.name + '.svg'),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(dealType.name) + " ")])], 1);
  }), 0) : _vm._e(), _vm.edit || _vm.chosenLinkedDeal || _vm.addressSelected ? _c('div', [_c('v-row', {
    staticClass: "subheading required"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("EstateType")))])]), _c('v-row', {
    staticClass: "box-wrapper"
  }, _vm._l(_vm.estateTypes, function (estateType) {
    return _c('button', {
      key: estateType.name,
      staticClass: "big-box2 icon-box",
      class: {
        'big-box2 icon-box': !estateType.isChosen,
        'big-box2-white icon-box active-box': estateType.isChosen
      },
      attrs: {
        "name": 'estate-type-' + estateType.value,
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          _vm.form.estateType = estateType.value;
          _vm.chooseBox2(estateType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [estateType.value !== 'office' && estateType.value !== 'building' && estateType.value !== 'room' && estateType.value !== 'storage' ? _c('img', {
      staticClass: "big-box2-image",
      class: {
        'big-box2-image': !estateType.isChosen,
        'big-box2-image-white': estateType.isChosen
      },
      attrs: {
        "src": require('../../assets/images/' + estateType.value + '.svg'),
        "alt": ""
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(estateType.name) + " ")])], 1);
  }), 0), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Address")))])]), _c('v-row', {
    staticClass: "address-selected"
  }, [_vm.form.street ? _c('p', [_vm._v(_vm._s(_vm.form.street))]) : _vm._e(), _vm.form.houseNumber ? _c('p', [_vm._v(" ")]) : _vm._e(), _vm.form.houseNumber ? _c('p', [_vm._v(_vm._s(_vm.form.houseNumber))]) : _vm._e(), _vm.form.apartmentNumber ? _c('p', [_vm._v("-" + _vm._s(_vm.form.apartmentNumber))]) : _vm._e(), _vm.form.houseNumber || _vm.form.street ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.cityPart))]), _vm.form.cityPart ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.district))]), _vm.form.district ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.city))]), _vm.form.county ? _c('p', [_vm._v(", ")]) : _vm._e(), _c('p', [_vm._v(_vm._s(_vm.form.county))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "justify-content": "space-between",
      "width": "100%",
      "gap": "12px"
    }
  }, [_c('v-col', {
    staticClass: "house-numbers",
    staticStyle: {
      "max-width": "50%"
    }
  }, [_c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "street",
      "placeholder": _vm.$t('Street')
    },
    model: {
      value: _vm.form.street,
      callback: function ($$v) {
        _vm.$set(_vm.form, "street", $$v);
      },
      expression: "form.street"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "house-number",
      "placeholder": _vm.$t('HouseNumber')
    },
    model: {
      value: _vm.form.houseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "houseNumber", $$v);
      },
      expression: "form.houseNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showHouseNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showHouseNumber", $$v);
      },
      expression: "form.showHouseNumber"
    }
  })], 1), _vm.form.estateType == 'apartment' || _vm.form.estateType == 'house_part' ? _c('v-row', {
    staticStyle: {
      "gap": "24px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "apartment-number",
      "type": "number",
      "placeholder": _vm.$t('ApartmentNumber')
    },
    model: {
      value: _vm.form.apartmentNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "apartmentNumber", $$v);
      },
      expression: "form.apartmentNumber"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('Show'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showApartmentNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showApartmentNumber", $$v);
      },
      expression: "form.showApartmentNumber"
    }
  })], 1) : _vm._e()], 1), _c('v-col', [_c('div', {
    attrs: {
      "id": "map"
    }
  }, [_c('GmapMap', {
    staticStyle: {
      "width": "432px",
      "height": "240px"
    },
    attrs: {
      "center": _vm.mapCoordinates,
      "zoom": 18,
      "map-type-id": "terrain"
    },
    on: {
      "click": _vm.setMarker
    }
  }, [_c('GmapMarker', {
    attrs: {
      "id": "marker",
      "position": _vm.markerCoordinates,
      "clickable": true,
      "draggable": true
    },
    on: {
      "drag": _vm.updateMarker
    }
  })], 1)], 1)])], 1)], 1) : _vm._e()], 1), _vm.edit || _vm.chosenLinkedDeal || _vm.addressSelected ? _c('div', [_c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("2")]), _c('h5', [_vm._v(_vm._s(_vm.$t("Price")) + " & " + _vm._s(_vm.$t("Availability")))])]), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "gap": "12px",
      "margin-top": "32px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "210px"
    },
    attrs: {
      "id": "price",
      "format": 'price',
      "placeholder": _vm.$t('Price'),
      "required": true,
      "error": _vm.errors,
      "icon-right": "Price.svg",
      "value-changed": _vm.valueChanged
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'price';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.price,
      callback: function ($$v) {
        _vm.$set(_vm.form, "price", $$v);
      },
      expression: "form.price"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "210px"
    },
    attrs: {
      "id": "square-meter-price",
      "placeholder": _vm.$t('PricePerUnit'),
      "icon-right": "eur+m2.svg",
      "value-changed": _vm.valueChanged
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'pricePerSquareMeter';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.pricePerSquareMeter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pricePerSquareMeter", $$v);
      },
      expression: "form.pricePerSquareMeter"
    }
  })], 1), _vm.form.dealType !== 'sale' ? _c('v-row', [_c('v-checkbox', {
    staticClass: "chechkbox-parameters",
    staticStyle: {
      "padding-top": "18px",
      "padding-left": "12px"
    },
    attrs: {
      "label": _vm.$t('RentToOwn'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.rentToOwn,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rentToOwn", $$v);
      },
      expression: "form.rentToOwn"
    }
  })], 1) : _vm._e(), _vm.form.dealType !== 'short_term_rent' ? _c('v-row', [_vm.form.dealType !== 'short_term_rent' ? _c('v-checkbox', {
    staticClass: "chechkbox-parameters",
    staticStyle: {
      "padding-left": "12px",
      "padding-top": "16px"
    },
    attrs: {
      "label": _vm.$t('Tradable'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.additionalInfo.tradable,
      callback: function ($$v) {
        _vm.$set(_vm.form.additionalInfo, "tradable", $$v);
      },
      expression: "form.additionalInfo.tradable"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('v-row', [_c('v-checkbox', {
    staticStyle: {
      "padding-left": "12px",
      "padding-top": "16px",
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('ImmediatelyAvailable'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.availableImmediately,
      callback: function ($$v) {
        _vm.$set(_vm.form, "availableImmediately", $$v);
      },
      expression: "form.availableImmediately"
    }
  })], 1), _vm.form.availableImmediately ? _c('v-row', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.dateAvailable,
      expression: "form.dateAvailable"
    }],
    staticClass: "birth-class",
    attrs: {
      "disabled": "",
      "type": "date",
      "name": "session-date"
    },
    domProps: {
      "value": _vm.form.dateAvailable
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "dateAvailable", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "v-label v-label--active theme--light",
    staticStyle: {
      "margin-top": "-5px",
      "position": "absolute",
      "font-size": "12px",
      "background-color": "white",
      "margin-left": "12px"
    },
    attrs: {
      "for": "input-112"
    }
  }, [_vm._v(_vm._s(_vm.$t("DateAvailable")))])]) : _vm._e(), !_vm.form.availableImmediately ? _c('v-row', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.dateAvailable,
      expression: "form.dateAvailable"
    }],
    staticClass: "birth-class",
    attrs: {
      "type": "date",
      "max": "9999-12-31",
      "name": "session-date"
    },
    domProps: {
      "value": _vm.form.dateAvailable
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "dateAvailable", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "v-label v-label--active theme--light",
    staticStyle: {
      "margin-top": "-5px",
      "position": "absolute",
      "font-size": "12px",
      "background-color": "white",
      "margin-left": "12px"
    },
    attrs: {
      "for": "input-112"
    }
  }, [_vm._v(_vm._s(_vm.$t("DateAvailable")))])]) : _vm._e()], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("3")]), _c('h5', [_vm._v(_vm._s(_vm.$t("General")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "32px",
      "gap": "32px"
    }
  }, [_c('v-col', {
    staticClass: "first-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_c('v-row', {
    staticClass: "floor-items"
  }, [_vm.form.estateType !== 'land' ? _c('BaseInput', {
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "id": "floors",
      "type": "number",
      "placeholder": _vm.$t('FloorCount')
    },
    model: {
      value: _vm.form.floors,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floors", $$v);
      },
      expression: "form.floors"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' || _vm.form.estateType === 'apartment' || _vm.form.estateType === 'commercial' ? _c('BaseInput', {
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "id": "floor",
      "type": "number",
      "placeholder": _vm.$t('Floor')
    },
    model: {
      value: _vm.form.floor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floor", $$v);
      },
      expression: "form.floor"
    }
  }) : _vm._e()], 1), _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "build-year",
      "type": "number",
      "placeholder": _vm.$t('BuildYear')
    },
    model: {
      value: _vm.form.buildYear,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildYear", $$v);
      },
      expression: "form.buildYear"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "general-surfaceArea",
      "placeholder": _vm.$t('GeneralSurfaceArea'),
      "icon-right": "SquareMeter.svg"
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'surfaceArea';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.generalSurfaceArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "generalSurfaceArea", $$v);
      },
      expression: "form.generalSurfaceArea"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "area-usable",
      "placeholder": _vm.$t('AreaUsable'),
      "icon-right": "SquareMeter.svg",
      "value-changed": _vm.valueChanged
    },
    model: {
      value: _vm.form.areaUsable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "areaUsable", $$v);
      },
      expression: "form.areaUsable"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "area-buildable",
      "type": "number",
      "placeholder": _vm.$t('AreaBuildable')
    },
    model: {
      value: _vm.form.areaBuildable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "areaBuildable", $$v);
      },
      expression: "form.areaBuildable"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'apartment' ? _c('v-row', {
    staticStyle: {
      "gap": "12px",
      "align-items": "center",
      "flex-wrap": "nowrap"
    }
  }, [_vm.landAreaUnit === 'm2' ? _c('BaseInput', {
    staticStyle: {
      "width": "210px"
    },
    attrs: {
      "id": "land-area-m2",
      "placeholder": _vm.$t('LandArea'),
      "value-changed": _vm.valueChanged
    },
    on: {
      "click": function ($event) {
        _vm.activePriceCalculatingInput = 'm2';
      },
      "change": _vm.priceCalculate
    },
    model: {
      value: _vm.form.landArea.m2,
      callback: function ($$v) {
        _vm.$set(_vm.form.landArea, "m2", $$v);
      },
      expression: "form.landArea.m2"
    }
  }) : _vm._e(), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.landAreaUnit === 'ha',
      expression: "landAreaUnit === 'ha'"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.landArea.ha,
      expression: "form.landArea.ha"
    }],
    staticStyle: {
      "width": "210px",
      "height": "52px",
      "outline": "1px solid rgb(230, 232, 236)",
      "padding": "8px 12px",
      "cursor": "text",
      "border-radius": "8px"
    },
    attrs: {
      "id": "land-area-ha",
      "type": "text",
      "placeholder": _vm.$t('LandArea')
    },
    domProps: {
      "value": _vm.form.landArea.ha
    },
    on: {
      "focus": function ($event) {
        _vm.activePriceCalculatingInput = 'ha';
      },
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form.landArea, "ha", $event.target.value);
      }, _vm.priceCalculate]
    }
  }), _c('div', {
    staticClass: "input-wrapper",
    staticStyle: {
      "max-height": "fit-content"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.landAreaUnit,
      callback: function ($$v) {
        _vm.landAreaUnit = $$v;
      },
      expression: "landAreaUnit"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": 'm2',
      "color": "orange darken-3",
      "value": 'm2'
    }
  }), _c('v-radio', {
    attrs: {
      "label": 'ha',
      "color": "orange darken-3",
      "value": 'ha'
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.form.estateType !== 'apartment' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "living-area",
      "type": "number",
      "placeholder": _vm.$t('LivingArea'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.form.livingArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "livingArea", $$v);
      },
      expression: "form.livingArea"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "rooms",
      "type": "number",
      "placeholder": _vm.$t('RoomCount')
    },
    model: {
      value: _vm.form.rooms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rooms", $$v);
      },
      expression: "form.rooms"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "bedrooms",
      "type": "number",
      "placeholder": _vm.$t('BedroomCount')
    },
    model: {
      value: _vm.form.bedrooms,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bedrooms", $$v);
      },
      expression: "form.bedrooms"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'land' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "width": "432px"
    },
    attrs: {
      "id": "bathroom-Count",
      "type": "number",
      "placeholder": _vm.$t('BathroomCount')
    },
    model: {
      value: _vm.form.bathroomCount,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bathroomCount", $$v);
      },
      expression: "form.bathroomCount"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'apartment' ? _c('v-row', [_c('v-checkbox', {
    attrs: {
      "id": "rooms-separate",
      "label": _vm.$t('RoomsSeparate'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.roomsSeparate,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roomsSeparate", $$v);
      },
      expression: "form.roomsSeparate"
    }
  })], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "second-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_vm.form.estateType != 'land' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "condition-select",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.conditions,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Condition')
    },
    model: {
      value: _vm.form.condition,
      callback: function ($$v) {
        _vm.$set(_vm.form, "condition", $$v);
      },
      expression: "form.condition"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.buildingTypes,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('BuildingType')
    },
    model: {
      value: _vm.form.buildingType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingType", $$v);
      },
      expression: "form.buildingType"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.readinesses,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Readiness')
    },
    model: {
      value: _vm.form.readiness,
      callback: function ($$v) {
        _vm.$set(_vm.form, "readiness", $$v);
      },
      expression: "form.readiness"
    }
  }) : _vm._e(), _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "outlined": "",
      "items": _vm.ownershipForms,
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('FormOfOwnership')
    },
    model: {
      value: _vm.form.ownershipForm,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ownershipForm", $$v);
      },
      expression: "form.ownershipForm"
    }
  }), _vm.form.estateType != 'land' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "outlined": "",
      "items": _vm.buildingMaterials,
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('BuildingMaterial')
    },
    model: {
      value: _vm.form.buildingMaterial,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingMaterial", $$v);
      },
      expression: "form.buildingMaterial"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.energyLabels,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('EnergyLabel')
    },
    model: {
      value: _vm.form.energyLabel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "energyLabel", $$v);
      },
      expression: "form.energyLabel"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "energy-certificate-law",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.energyCertificateLaws,
      "item-text": "name",
      "item-value": "name",
      "outlined": "",
      "label": _vm.$t('EnergyCertificateLaw')
    },
    model: {
      value: _vm.form.energyCertificateLaw,
      callback: function ($$v) {
        _vm.$set(_vm.form, "energyCertificateLaw", $$v);
      },
      expression: "form.energyCertificateLaw"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' || _vm.form.estateType === 'building' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "energy-certificate-performance",
      "placeholder": _vm.$t('EnergyCertificatePerformance'),
      "type": "number"
    },
    model: {
      value: _vm.form.energyCertificatePerformance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "energyCertificatePerformance", $$v);
      },
      expression: "form.energyCertificatePerformance"
    }
  })], 1) : _vm._e(), _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.limitations,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Limitations')
    },
    model: {
      value: _vm.form.limitations,
      callback: function ($$v) {
        _vm.$set(_vm.form, "limitations", $$v);
      },
      expression: "form.limitations"
    }
  }), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'garage' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "expenses-in-summer",
      "type": "number",
      "placeholder": _vm.$t('ExpensesInSummer'),
      "icon-right": "Price.svg"
    },
    on: {
      "change": _vm.calculateExpenses
    },
    model: {
      value: _vm.form.expensesInSummer,
      callback: function ($$v) {
        _vm.$set(_vm.form, "expensesInSummer", $$v);
      },
      expression: "form.expensesInSummer"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'garage' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "expenses-in-winter",
      "type": "number",
      "placeholder": _vm.$t('ExpensesInWinter'),
      "icon-right": "Price.svg"
    },
    on: {
      "change": _vm.calculateExpenses
    },
    model: {
      value: _vm.form.expensesInWinter,
      callback: function ($$v) {
        _vm.$set(_vm.form, "expensesInWinter", $$v);
      },
      expression: "form.expensesInWinter"
    }
  })], 1) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.detailedPlans,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('DetailPlan')
    },
    model: {
      value: _vm.form.detailedPlan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "detailedPlan", $$v);
      },
      expression: "form.detailedPlan"
    }
  }) : _vm._e()], 1)], 1), _vm.form.estateType == 'land' ? _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("LandPurposeOfUse")))])]) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-row', {
    staticStyle: {
      "gap": "32px"
    }
  }, [_c('v-col', {
    staticClass: "first-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "20px"
    },
    attrs: {
      "label": _vm.$t('CommercialLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.commercialLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "commercialLand", $$v);
      },
      expression: "form.commercialLand"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('ProductionLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.productionLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "productionLand", $$v);
      },
      expression: "form.productionLand"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('IndustrialLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.industrialLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "industrialLand", $$v);
      },
      expression: "form.industrialLand"
    }
  }) : _vm._e(), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('ForestLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.forestLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "forestLand", $$v);
      },
      expression: "form.forestLand"
    }
  })], 1), _c('v-col', {
    staticClass: "second-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": _vm.$t('ResidentialLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.residentialLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "residentialLand", $$v);
      },
      expression: "form.residentialLand"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "20px"
    },
    attrs: {
      "label": _vm.$t('ProfitYieldingLand'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.profityieldingLand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "profityieldingLand", $$v);
      },
      expression: "form.profityieldingLand"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('ResidentialLandApartments'),
      "value": "",
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.residentialLandApartment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "residentialLandApartment", $$v);
      },
      expression: "form.residentialLandApartment"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('OtherUse'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.otherUse,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otherUse", $$v);
      },
      expression: "form.otherUse"
    }
  })], 1)], 1) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("CommercialPurposeOfUse")))])]) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-row', {
    staticStyle: {
      "gap": "32px"
    }
  }, [_c('v-col', {
    staticClass: "first-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_vm.form.estateType === 'commercial' && _vm.form.dealType === 'rent' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "price-transfer",
      "placeholder": _vm.$t('PriceTransfer'),
      "type": "number"
    },
    model: {
      value: _vm.form.priceTransfer,
      callback: function ($$v) {
        _vm.$set(_vm.form, "priceTransfer", $$v);
      },
      expression: "form.priceTransfer"
    }
  })], 1) : _vm._e(), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "20px"
    },
    attrs: {
      "label": _vm.$t('Office'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.office,
      callback: function ($$v) {
        _vm.$set(_vm.form, "office", $$v);
      },
      expression: "form.office"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('TradeShop'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.tradeshop,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tradeshop", $$v);
      },
      expression: "form.tradeshop"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('Accomodation'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.accomodation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accomodation", $$v);
      },
      expression: "form.accomodation"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('Services'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.services,
      callback: function ($$v) {
        _vm.$set(_vm.form, "services", $$v);
      },
      expression: "form.services"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('WareHouse'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.warehouse,
      callback: function ($$v) {
        _vm.$set(_vm.form, "warehouse", $$v);
      },
      expression: "form.warehouse"
    }
  })], 1), _c('v-col', {
    staticClass: "second-general-column",
    staticStyle: {
      "min-width": "432px",
      "max-width": "432px"
    }
  }, [_c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "20px"
    },
    attrs: {
      "label": _vm.$t('Production'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.production,
      callback: function ($$v) {
        _vm.$set(_vm.form, "production", $$v);
      },
      expression: "form.production"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('FoodServices'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.foodServices,
      callback: function ($$v) {
        _vm.$set(_vm.form, "foodServices", $$v);
      },
      expression: "form.foodServices"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('NotDefined'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.notDefined,
      callback: function ($$v) {
        _vm.$set(_vm.form, "notDefined", $$v);
      },
      expression: "form.notDefined"
    }
  }), _c('v-checkbox', {
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "label": _vm.$t('WareHouseProduction'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.warehouseProduction,
      callback: function ($$v) {
        _vm.$set(_vm.form, "warehouseProduction", $$v);
      },
      expression: "form.warehouseProduction"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('OtherUsePossible'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.otherUsePossible,
      callback: function ($$v) {
        _vm.$set(_vm.form, "otherUsePossible", $$v);
      },
      expression: "form.otherUsePossible"
    }
  })], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("4")]), _c('h5', [_vm._v(_vm._s(_vm.$t("AdditionalInfo")))])]), _c('v-row', [_c('v-row', {
    staticClass: "box-wrapper",
    staticStyle: {
      "padding-top": "20px"
    }
  }, _vm._l(_vm.sectionTypes, function (sectionType) {
    return _c('button', {
      key: sectionType.name,
      staticClass: "big-box2 icon-box",
      class: {
        'big-box2 icon-box': !sectionType.isChosen,
        'big-box2-white icon-box active-box': sectionType.isChosen
      },
      attrs: {
        "id": sectionType.value,
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          _vm.form.sectionType = sectionType.value;
          _vm.chooseSection2(sectionType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [_c('img', {
      staticClass: "big-box2-image2",
      class: {
        'big-box2-image': !sectionType.isChosen,
        'big-box2-image-white': sectionType.isChosen
      },
      attrs: {
        "src": require('../../assets/images/' + sectionType.name + '.svg'),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(sectionType.name) + " ")])], 1);
  }), 0), _vm.form.sectionType != 'sanitary' && _vm.form.sectionType != 'surroundings' && _vm.form.sectionType != 'communication' && _vm.form.sectionType != 'heating' && _vm.form.sectionType != 'kitchen' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticClass: "additional-first-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType === 'room' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "life-style",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.lifeStyles,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('LifeStyle')
    },
    model: {
      value: _vm.form.lifeStyle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lifeStyle", $$v);
      },
      expression: "form.lifeStyle"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "interior-accessibility",
      "label": _vm.$t('InteriorAccessibility'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.interiorAccessibility,
      callback: function ($$v) {
        _vm.$set(_vm.form, "interiorAccessibility", $$v);
      },
      expression: "form.interiorAccessibility"
    }
  }) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "exterior-accessibility",
      "label": _vm.$t('ExteriorAccessibility'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.exteriorAccessibility,
      callback: function ($$v) {
        _vm.$set(_vm.form, "exteriorAccessibility", $$v);
      },
      expression: "form.exteriorAccessibility"
    }
  }) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "max-age",
      "placeholder": _vm.$t('MaxAge'),
      "type": "number"
    },
    model: {
      value: _vm.form.maxAge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "maxAge", $$v);
      },
      expression: "form.maxAge"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "min-age",
      "placeholder": _vm.$t('MinAge'),
      "type": "number"
    },
    model: {
      value: _vm.form.minAge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "minAge", $$v);
      },
      expression: "form.minAge"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "tenant-students",
      "label": _vm.$t('TenantStudents'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.tenantStudents,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tenantStudents", $$v);
      },
      expression: "form.tenantStudents"
    }
  }) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "occupied-now",
      "label": _vm.$t('OccupiedNow'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.occupiedNow,
      callback: function ($$v) {
        _vm.$set(_vm.form, "occupiedNow", $$v);
      },
      expression: "form.occupiedNow"
    }
  }) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "lgtb-friendly",
      "label": _vm.$t('LgtbFriendly'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.lgtbFriendly,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lgtbFriendly", $$v);
      },
      expression: "form.lgtbFriendly"
    }
  }) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "occupation",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.occupations,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Occupation')
    },
    model: {
      value: _vm.form.occupation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "occupation", $$v);
      },
      expression: "form.occupation"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "gender-preference",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.genderPreferences,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('TenantGender')
    },
    model: {
      value: _vm.form.genderPreference,
      callback: function ($$v) {
        _vm.$set(_vm.form, "genderPreference", $$v);
      },
      expression: "form.genderPreference"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "private-bathroom",
      "label": _vm.$t('PrivateBathroom'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.privateBathroom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "privateBathroom", $$v);
      },
      expression: "form.privateBathroom"
    }
  }) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "house-keeper",
      "label": _vm.$t('HouseKeeper'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.houseKeeper,
      callback: function ($$v) {
        _vm.$set(_vm.form, "houseKeeper", $$v);
      },
      expression: "form.houseKeeper"
    }
  }) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "tenant-gender",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.tenantGenders,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('TenantGender')
    },
    model: {
      value: _vm.form.tenantGender,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tenantGender", $$v);
      },
      expression: "form.tenantGender"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "owner-living",
      "label": _vm.$t('OwnerLiving'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.ownerLiving,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ownerLiving", $$v);
      },
      expression: "form.ownerLiving"
    }
  }) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "window-View",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.windowViews,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('WindowViews')
    },
    model: {
      value: _vm.form.windowView,
      callback: function ($$v) {
        _vm.$set(_vm.form, "windowView", $$v);
      },
      expression: "form.windowView"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "minimal-stay",
      "placeholder": _vm.$t('MinimalStay'),
      "type": "number"
    },
    model: {
      value: _vm.form.minimalStay,
      callback: function ($$v) {
        _vm.$set(_vm.form, "minimalStay", $$v);
      },
      expression: "form.minimalStay"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "bed-type",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.bedTypes,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('BedType')
    },
    model: {
      value: _vm.form.bedType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bedType", $$v);
      },
      expression: "form.bedType"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "couples-allowed",
      "label": _vm.$t('CouplesAllowed'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.couplesAllowed,
      callback: function ($$v) {
        _vm.$set(_vm.form, "couplesAllowed", $$v);
      },
      expression: "form.couplesAllowed"
    }
  }) : _vm._e(), _vm.form.estateType === 'building' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "max-tenant-age",
      "placeholder": _vm.$t('MaxTenantAge'),
      "type": "number"
    },
    model: {
      value: _vm.form.maxTenantAge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "maxTenantAge", $$v);
      },
      expression: "form.maxTenantAge"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'building' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "min-tenant-age",
      "placeholder": _vm.$t('MinTenantAge'),
      "type": "number"
    },
    model: {
      value: _vm.form.minTenantAge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "minTenantAge", $$v);
      },
      expression: "form.minTenantAge"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "smoking-allowed",
      "label": _vm.$t('SmokingAllowed'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.smokingAllowed,
      callback: function ($$v) {
        _vm.$set(_vm.form, "smokingAllowed", $$v);
      },
      expression: "form.smokingAllowed"
    }
  }) : _vm._e(), _vm.form.estateType === 'building' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "built-properties",
      "placeholder": _vm.$t('BuiltProperties'),
      "type": "number"
    },
    model: {
      value: _vm.form.builtProperties,
      callback: function ($$v) {
        _vm.$set(_vm.form, "builtProperties", $$v);
      },
      expression: "form.builtProperties"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'building' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "property-tenants",
      "placeholder": _vm.$t('PropertyTenants'),
      "type": "number"
    },
    model: {
      value: _vm.form.propertyTenants,
      callback: function ($$v) {
        _vm.$set(_vm.form, "propertyTenants", $$v);
      },
      expression: "form.propertyTenants"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'storage' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "area-height",
      "placeholder": _vm.$t('AreaHeight'),
      "type": "number"
    },
    model: {
      value: _vm.form.areaHeight,
      callback: function ($$v) {
        _vm.$set(_vm.form, "areaHeight", $$v);
      },
      expression: "form.areaHeight"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "natural-gas",
      "label": _vm.$t('NaturalGas'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.naturalGas,
      callback: function ($$v) {
        _vm.$set(_vm.form, "naturalGas", $$v);
      },
      expression: "form.naturalGas"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "classification-public",
      "label": _vm.$t('ClassificationPublic'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.classificationPublic,
      callback: function ($$v) {
        _vm.$set(_vm.form, "classificationPublic", $$v);
      },
      expression: "form.classificationPublic"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' || _vm.form.estateType === 'building' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "classification-other",
      "label": _vm.$t('ClassificationOther'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.classificationOther,
      callback: function ($$v) {
        _vm.$set(_vm.form, "classificationOther", $$v);
      },
      expression: "form.classificationOther"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' || _vm.form.estateType === 'building' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "classification-office",
      "label": _vm.$t('ClassificationOffice'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.classificationOffice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "classificationOffice", $$v);
      },
      expression: "form.classificationOffice"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' || _vm.form.estateType === 'building' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "classification-industrial",
      "label": _vm.$t('ClassificationIndustrial'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.classificationIndustrial,
      callback: function ($$v) {
        _vm.$set(_vm.form, "classificationIndustrial", $$v);
      },
      expression: "form.classificationIndustrial"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' || _vm.form.estateType === 'building' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "classification-hotel",
      "label": _vm.$t('ClassificationHotel'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.classificationHotel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "classificationHotel", $$v);
      },
      expression: "form.classificationHotel"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' || _vm.form.estateType === 'building' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "classification-commercial",
      "label": _vm.$t('ClassificationCommercial'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.classificationCommercial,
      callback: function ($$v) {
        _vm.$set(_vm.form, "classificationCommercial", $$v);
      },
      expression: "form.classificationCommercial"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' || _vm.form.estateType === 'building' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "classification-chalet",
      "label": _vm.$t('ClassificationChalet'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.classificationChalet,
      callback: function ($$v) {
        _vm.$set(_vm.form, "classificationChalet", $$v);
      },
      expression: "form.classificationChalet"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "classification-blocks",
      "label": _vm.$t('ClassificationBlocks'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.classificationBlocks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "classificationBlocks", $$v);
      },
      expression: "form.classificationBlocks"
    }
  }) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "windows-number",
      "placeholder": _vm.$t('WindowsNumber'),
      "type": "number"
    },
    model: {
      value: _vm.form.windowsNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "windowsNumber", $$v);
      },
      expression: "form.windowsNumber"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "smoke-extraction",
      "label": _vm.$t('SmokeExtraction'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.smokeExtraction,
      callback: function ($$v) {
        _vm.$set(_vm.form, "smokeExtraction", $$v);
      },
      expression: "form.smokeExtraction"
    }
  }) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "located-at-corner",
      "label": _vm.$t('LocatedAtCorner'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.locatedAtCorner,
      callback: function ($$v) {
        _vm.$set(_vm.form, "locatedAtCorner", $$v);
      },
      expression: "form.locatedAtCorner"
    }
  }) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "loading-dock",
      "label": _vm.$t('LoadingDock'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.loadingDock,
      callback: function ($$v) {
        _vm.$set(_vm.form, "loadingDock", $$v);
      },
      expression: "form.loadingDock"
    }
  }) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "last-activity",
      "placeholder": _vm.$t('LastActivity')
    },
    model: {
      value: _vm.form.lastActivity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lastActivity", $$v);
      },
      expression: "form.lastActivity"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "facade-area",
      "placeholder": _vm.$t('FacadeArea'),
      "icon-right": "SquareMeter.svg",
      "type": "number"
    },
    model: {
      value: _vm.form.facadeArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "facadeArea", $$v);
      },
      expression: "form.facadeArea"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "bridge-crane",
      "label": _vm.$t('BridgeCrane'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.bridgeCrane,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bridgeCrane", $$v);
      },
      expression: "form.bridgeCrane"
    }
  }) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "bathroom-adapted",
      "label": _vm.$t('BathroomAdapted'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.bathroomAdapted,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bathroomAdapted", $$v);
      },
      expression: "form.bathroomAdapted"
    }
  }) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "auxiliary-entrance",
      "label": _vm.$t('AuxiliaryEntrance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.auxiliaryEntrance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "auxiliaryEntrance", $$v);
      },
      expression: "form.auxiliaryEntrance"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' || _vm.form.estateType === 'land' || _vm.form.estateType === 'building' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "area-tradable-minimum",
      "placeholder": _vm.$t('AreaTradableMinimum'),
      "type": "number"
    },
    model: {
      value: _vm.form.areaTradableMinimum,
      callback: function ($$v) {
        _vm.$set(_vm.form, "areaTradableMinimum", $$v);
      },
      expression: "form.areaTradableMinimum"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "windows-double",
      "label": _vm.$t('WindowsDouble'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.windowsDouble,
      callback: function ($$v) {
        _vm.$set(_vm.form, "windowsDouble", $$v);
      },
      expression: "form.windowsDouble"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "suspended-floor",
      "label": _vm.$t('SuspendedFloor'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.suspendedFloor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "suspendedFloor", $$v);
      },
      expression: "form.suspendedFloor"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "suspended-ceiling",
      "label": _vm.$t('SuspendedCeiling'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.suspendedCeiling,
      callback: function ($$v) {
        _vm.$set(_vm.form, "suspendedCeiling", $$v);
      },
      expression: "form.suspendedCeiling"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "rooms-splitted",
      "label": _vm.$t('RoomsSplitted'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.roomsSplitted,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roomsSplitted", $$v);
      },
      expression: "form.roomsSplitted"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "rooms-splitted",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.roomsSplitteds,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('RoomsSplitted')
    },
    model: {
      value: _vm.form.roomsSplitted,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roomsSplitted", $$v);
      },
      expression: "form.roomsSplitted"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "office-building",
      "label": _vm.$t('OfficeBuilding'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.officeBuilding,
      callback: function ($$v) {
        _vm.$set(_vm.form, "officeBuilding", $$v);
      },
      expression: "form.officeBuilding"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' || _vm.form.estateType === 'building' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "lift-number",
      "placeholder": _vm.$t('LiftNumber'),
      "type": "number"
    },
    model: {
      value: _vm.form.liftNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "liftNumber", $$v);
      },
      expression: "form.liftNumber"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "floors-property",
      "placeholder": _vm.$t('FloorsProperty'),
      "type": "number"
    },
    model: {
      value: _vm.form.floorsProperty,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floorsProperty", $$v);
      },
      expression: "form.floorsProperty"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "fire-doors",
      "label": _vm.$t('FireDoors'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fireDoors,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fireDoors", $$v);
      },
      expression: "form.fireDoors"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "fireDetectors",
      "label": _vm.$t('FireDetectors'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fireDetectors,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fireDetectors", $$v);
      },
      expression: "form.fireDetectors"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "extinguishers",
      "label": _vm.$t('Extinguishers'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.extinguishers,
      callback: function ($$v) {
        _vm.$set(_vm.form, "extinguishers", $$v);
      },
      expression: "form.extinguishers"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "emergency-lights",
      "label": _vm.$t('EmergencyLights'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.emergencyLights,
      callback: function ($$v) {
        _vm.$set(_vm.form, "emergencyLights", $$v);
      },
      expression: "form.emergencyLights"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "emergency-exit",
      "label": _vm.$t('EmergencyExit'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.emergencyExit,
      callback: function ($$v) {
        _vm.$set(_vm.form, "emergencyExit", $$v);
      },
      expression: "form.emergencyExit"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' || _vm.form.estateType === 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "doorman",
      "label": _vm.$t('Doorman'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.doorman,
      callback: function ($$v) {
        _vm.$set(_vm.form, "doorman", $$v);
      },
      expression: "form.doorman"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "security-camera",
      "label": _vm.$t('BuildingAdapted'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.buildingAdapted,
      callback: function ($$v) {
        _vm.$set(_vm.form, "buildingAdapted", $$v);
      },
      expression: "form.buildingAdapted"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "rooms-splitted",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.roomsSplitteds,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('ParkingType')
    },
    model: {
      value: _vm.form.parkingType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parkingType", $$v);
      },
      expression: "form.parkingType"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'garage' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "rooms-splitted",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.roomsSplitteds,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('RoomsSplitted')
    },
    model: {
      value: _vm.form.roomsSplitted,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roomsSplitted", $$v);
      },
      expression: "form.roomsSplitted"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "parking-place-covered",
      "label": _vm.$t('parkingPlaceCovered'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.parkingPlaceCovered,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parkingPlaceCovered", $$v);
      },
      expression: "form.parkingPlaceCovered"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "parking-automatic-door",
      "label": _vm.$t('ParkingAutomaticDoor'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.parkingAutomaticDoor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parkingAutomaticDoor", $$v);
      },
      expression: "form.parkingAutomaticDoor"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "garage-capacity",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.garageCapacities,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('GarageCapacity')
    },
    model: {
      value: _vm.form.garageCapacity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "garageCapacity", $$v);
      },
      expression: "form.garageCapacity"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "chimney",
      "label": _vm.$t('Chimney'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.chimney,
      callback: function ($$v) {
        _vm.$set(_vm.form, "chimney", $$v);
      },
      expression: "form.chimney"
    }
  }) : _vm._e(), _vm.form.estateType !== 'apartment' || _vm.form.estateType !== 'room' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "current-occupations",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.currentOccupations,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('CurrentOccupation')
    },
    model: {
      value: _vm.form.currentOccupation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currentOccupation", $$v);
      },
      expression: "form.currentOccupation"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'house' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "rooms-splitted",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.floorLevels,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('FloorLevel')
    },
    model: {
      value: _vm.form.floorLevel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floorLevel", $$v);
      },
      expression: "form.floorLevel"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'house' || _vm.form.estateType === 'building' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "garden",
      "label": _vm.$t('Garden'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.garden,
      callback: function ($$v) {
        _vm.$set(_vm.form, "garden", $$v);
      },
      expression: "form.garden"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "outdoor-parking-space-number",
      "placeholder": _vm.$t('OutdoorParkingSpaceNumber'),
      "type": "number"
    },
    model: {
      value: _vm.form.outdoorParkingSpaceNumber,
      callback: function ($$v) {
        _vm.$set(_vm.form, "outdoorParkingSpaceNumber", $$v);
      },
      expression: "form.outdoorParkingSpaceNumber"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "outdoor-parking-space-type",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.outdoorParkingSpaceTypes,
      "item-text": "name",
      "item-value": "name",
      "outlined": "",
      "label": _vm.$t('OutdoorParkingSpaceType')
    },
    model: {
      value: _vm.form.outdoorParkingSpaceType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "outdoorParkingSpaceType", $$v);
      },
      expression: "form.outdoorParkingSpaceType"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "outdoor-parking-space-available",
      "label": _vm.$t('OutdoorParkingSpaceAvailable'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.outdoorParkingSpaceAvailable,
      callback: function ($$v) {
        _vm.$set(_vm.form, "outdoorParkingSpaceAvailable", $$v);
      },
      expression: "form.outdoorParkingSpaceAvailable"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      "id": "parking-space-area",
      "placeholder": _vm.$t('ParkingSpaceArea'),
      "icon-right": "SquareMeter.svg",
      "type": "number"
    },
    model: {
      value: _vm.form.parkingSpaceArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parkingSpaceArea", $$v);
      },
      expression: "form.parkingSpaceArea"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "garden-type",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.gardenTypes,
      "item-text": "name",
      "item-value": "name",
      "outlined": "",
      "label": _vm.$t('GardenType')
    },
    model: {
      value: _vm.form.gardenType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gardenType", $$v);
      },
      expression: "form.gardenType"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'room' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "auction-tribunal",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.auctionTribunals,
      "item-text": "name",
      "item-value": "name",
      "outlined": "",
      "label": _vm.$t('AuctionTribunal')
    },
    model: {
      value: _vm.form.auctionTribunal,
      callback: function ($$v) {
        _vm.$set(_vm.form, "auctionTribunal", $$v);
      },
      expression: "form.auctionTribunal"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'room' ? _c('v-row', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.auctionDate,
      expression: "form.auctionDate"
    }],
    staticClass: "birth-class",
    staticStyle: {
      "min-width": "345px"
    },
    attrs: {
      "type": "date",
      "max": "9999-12-31",
      "name": "session-date"
    },
    domProps: {
      "value": _vm.form.auctionDate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "auctionDate", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "v-label v-label--active theme--light",
    staticStyle: {
      "margin-top": "-5px",
      "position": "absolute",
      "font-size": "12px",
      "background-color": "white",
      "margin-left": "12px"
    },
    attrs: {
      "for": "input-112"
    }
  }, [_vm._v(_vm._s(_vm.$t("AuctionDate")))])]) : _vm._e(), _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'room' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "auction-deposit",
      "placeholder": _vm.$t('AuctionDeposit'),
      "type": "number"
    },
    model: {
      value: _vm.form.auctionDeposit,
      callback: function ($$v) {
        _vm.$set(_vm.form, "auctionDeposit", $$v);
      },
      expression: "form.auctionDeposit"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'room' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "min-auction-bid-increment",
      "placeholder": _vm.$t('MinAuctionBidIncrement'),
      "type": "number"
    },
    model: {
      value: _vm.form.minAuctionBidIncrement,
      callback: function ($$v) {
        _vm.$set(_vm.form, "minAuctionBidIncrement", $$v);
      },
      expression: "form.minAuctionBidIncrement"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'room' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "studio",
      "label": _vm.$t('IsAuction'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.isAuction,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isAuction", $$v);
      },
      expression: "form.isAuction"
    }
  }) : _vm._e(), _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      "id": "depositmonths",
      "placeholder": _vm.$t('DepositMonths'),
      "type": "number"
    },
    model: {
      value: _vm.form.depositMonths,
      callback: function ($$v) {
        _vm.$set(_vm.form, "depositMonths", $$v);
      },
      expression: "form.depositMonths"
    }
  })], 1), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' || _vm.form.estateType === 'office' || _vm.form.estateType === 'commercial' || _vm.form.estateType === 'room' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      "id": "parking-price",
      "placeholder": _vm.$t('PriceCommunity'),
      "type": "number",
      "icon-right": "Price.svg"
    },
    model: {
      value: _vm.form.priceCommunity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "priceCommunity", $$v);
      },
      expression: "form.priceCommunity"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "studio",
      "label": _vm.$t('Studio'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.studio,
      callback: function ($$v) {
        _vm.$set(_vm.form, "studio", $$v);
      },
      expression: "form.studio"
    }
  }) : _vm._e(), _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "recommended-for-children",
      "label": _vm.$t('RecommendedForChildren'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.recommendedForChildren,
      callback: function ($$v) {
        _vm.$set(_vm.form, "recommendedForChildren", $$v);
      },
      expression: "form.recommendedForChildren"
    }
  }) : _vm._e(), _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' || _vm.form.estateType === 'room' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      "id": "parking-price",
      "placeholder": _vm.$t('TenantNumber')
    },
    model: {
      value: _vm.form.parkingPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parkingPrice", $$v);
      },
      expression: "form.parkingPrice"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'apartment' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-top": "12px"
    },
    attrs: {
      "id": "parking-price",
      "placeholder": _vm.$t('ParkingPrice'),
      "icon-right": "Price.svg",
      "type": "number"
    },
    model: {
      value: _vm.form.parkingPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parkingPrice", $$v);
      },
      expression: "form.parkingPrice"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "orientation-north",
      "label": _vm.$t('OrientationNorth'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.orientationNorth,
      callback: function ($$v) {
        _vm.$set(_vm.form, "orientationNorth", $$v);
      },
      expression: "form.orientationNorth"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "orientation-south",
      "label": _vm.$t('OrientationSouth'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.orientationSouth,
      callback: function ($$v) {
        _vm.$set(_vm.form, "orientationSouth", $$v);
      },
      expression: "form.orientationSouth"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "orientation-west",
      "label": _vm.$t('OrientationWest'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.orientationWest,
      callback: function ($$v) {
        _vm.$set(_vm.form, "orientationWest", $$v);
      },
      expression: "form.orientationWest"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "orientation-east",
      "label": _vm.$t('OrientationEast'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.orientationEast,
      callback: function ($$v) {
        _vm.$set(_vm.form, "orientationEast", $$v);
      },
      expression: "form.orientationEast"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "handicapped-adapted-access",
      "label": _vm.$t('HandicappedAdaptedAccess'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.handicappedAdaptedAccess,
      callback: function ($$v) {
        _vm.$set(_vm.form, "handicappedAdaptedAccess", $$v);
      },
      expression: "form.handicappedAdaptedAccess"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-exists",
      "label": _vm.$t('HandicappedAdaptedUse'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.handicappedAdaptedUse,
      callback: function ($$v) {
        _vm.$set(_vm.form, "handicappedAdaptedUse", $$v);
      },
      expression: "form.handicappedAdaptedUse"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-exists",
      "label": _vm.$t('Duplex'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.duplex,
      callback: function ($$v) {
        _vm.$set(_vm.form, "duplex", $$v);
      },
      expression: "form.duplex"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-exists",
      "label": _vm.$t('Balcony'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.balcony,
      callback: function ($$v) {
        _vm.$set(_vm.form, "balcony", $$v);
      },
      expression: "form.balcony"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-is-closed",
      "label": _vm.$t('ClosedBalcony'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.closedBalcony,
      callback: function ($$v) {
        _vm.$set(_vm.form, "closedBalcony", $$v);
      },
      expression: "form.closedBalcony"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-is-fully-glazed",
      "label": _vm.$t('FullyGlazedBalcony'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fullyGlazedBalcony,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fullyGlazedBalcony", $$v);
      },
      expression: "form.fullyGlazedBalcony"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "balcony-is-french",
      "label": _vm.$t('FrenchBalcony'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.frenchBalcony,
      callback: function ($$v) {
        _vm.$set(_vm.form, "frenchBalcony", $$v);
      },
      expression: "form.frenchBalcony"
    }
  }) : _vm._e(), _c('v-row', {
    staticStyle: {
      "margin-top": "0"
    }
  }), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'land' ? _c('v-row', {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "balcony-size",
      "type": "number",
      "placeholder": _vm.$t('Balcony'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.form.balconySize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "balconySize", $$v);
      },
      expression: "form.balconySize"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "kitchen-size",
      "placeholder": _vm.$t('Kitchen'),
      "icon-right": "SquareMeter.svg"
    },
    on: {
      "change": _vm.calculateKitchenSize
    },
    model: {
      value: _vm.form.kitchenSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kitchenSize", $$v);
      },
      expression: "form.kitchenSize"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-select', {
    staticStyle: {
      "margin-bottom": "-18px",
      "padding-top": "0px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.parkingOptions,
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('ParkingOption')
    },
    model: {
      value: _vm.form.parkingOption,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parkingOption", $$v);
      },
      expression: "form.parkingOption"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "8px"
    },
    attrs: {
      "id": "has-garage",
      "label": _vm.$t('Garage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.garage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "garage", $$v);
      },
      expression: "form.garage"
    }
  }) : _vm._e(), _vm.form.estateType === 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "garage-is-underground",
      "label": _vm.$t('UndergroundGarage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.undergroundGarage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "undergroundGarage", $$v);
      },
      expression: "form.undergroundGarage"
    }
  }) : _vm._e(), _c('v-row', {
    staticStyle: {
      "margin-top": "0"
    }
  }), _vm.form.estateType === 'commercial' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "kitchen-size",
      "placeholder": _vm.$t('Kitchen'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.form.kitchenSize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kitchenSize", $$v);
      },
      expression: "form.kitchenSize"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-bottom": "28px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "number-of-desks",
      "placeholder": _vm.$t('NumberOfDesks'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.form.numberOfDesks,
      callback: function ($$v) {
        _vm.$set(_vm.form, "numberOfDesks", $$v);
      },
      expression: "form.numberOfDesks"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'house' && _vm.form.estateType != 'house_part' && _vm.form.estateType != 'cottage' ? _c('v-select', {
    staticStyle: {
      "margin-top": "8px !important",
      "width": "432px"
    },
    attrs: {
      "clearable": "",
      "outlined": "",
      "items": _vm.roofTypes,
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('RoofType')
    },
    model: {
      value: _vm.form.roofType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roofType", $$v);
      },
      expression: "form.roofType"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' || _vm.form.estateType == 'house' || _vm.form.estateType == 'house_part' || _vm.form.estateType == 'cottage' ? _c('v-select', {
    staticStyle: {
      "margin-top": "24px !important",
      "width": "432px"
    },
    attrs: {
      "clearable": "",
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "items": _vm.roofTypes,
      "label": _vm.$t('RoofType')
    },
    model: {
      value: _vm.form.roofType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roofType", $$v);
      },
      expression: "form.roofType"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "0px",
      "margin-top": "-4px !important"
    },
    attrs: {
      "id": "has-elevator",
      "label": _vm.$t('Lift'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.lift,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lift", $$v);
      },
      expression: "form.lift"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-parquet",
      "label": _vm.$t('Parquet'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.parquet,
      callback: function ($$v) {
        _vm.$set(_vm.form, "parquet", $$v);
      },
      expression: "form.parquet"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-packet-windows",
      "label": _vm.$t('PacketWindows'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.packetWindows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "packetWindows", $$v);
      },
      expression: "form.packetWindows"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-furniture",
      "label": _vm.$t('Furniture'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.furniture,
      callback: function ($$v) {
        _vm.$set(_vm.form, "furniture", $$v);
      },
      expression: "form.furniture"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "is-furnishing-possible",
      "label": _vm.$t('FurnitureChangePossibility'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.furnitureAbility,
      callback: function ($$v) {
        _vm.$set(_vm.form, "furnitureAbility", $$v);
      },
      expression: "form.furnitureAbility"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "plan-through-multiple-floors",
      "label": _vm.$t('PlanThroughMultipleFloors'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.severalFloorsPlanning,
      callback: function ($$v) {
        _vm.$set(_vm.form, "severalFloorsPlanning", $$v);
      },
      expression: "form.severalFloorsPlanning"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-high-ceilings",
      "label": _vm.$t('HighCeilings'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.highCeilings,
      callback: function ($$v) {
        _vm.$set(_vm.form, "highCeilings", $$v);
      },
      expression: "form.highCeilings"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-new-wiring",
      "label": _vm.$t('NewWiring'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.electricalWiring,
      callback: function ($$v) {
        _vm.$set(_vm.form, "electricalWiring", $$v);
      },
      expression: "form.electricalWiring"
    }
  }) : _vm._e(), _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-electricity",
      "label": _vm.$t('Electricity'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.electricity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "electricity", $$v);
      },
      expression: "form.electricity"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-wardrobe",
      "label": _vm.$t('Wardrobe'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.wardrobe,
      callback: function ($$v) {
        _vm.$set(_vm.form, "wardrobe", $$v);
      },
      expression: "form.wardrobe"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "additional-second-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    attrs: {
      "id": "has-attic",
      "label": _vm.$t('Attic'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.attic,
      callback: function ($$v) {
        _vm.$set(_vm.form, "attic", $$v);
      },
      expression: "form.attic"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'house' && _vm.form.estateType != 'house_part' && _vm.form.estateType != 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-storeroom",
      "label": _vm.$t('Storeroom'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.storeRoom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "storeRoom", $$v);
      },
      expression: "form.storeRoom"
    }
  }) : _vm._e(), _vm.form.estateType == 'house' || _vm.form.estateType == 'house_part' || _vm.form.estateType == 'cottage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-storeroom",
      "label": _vm.$t('Storeroom'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.storeRoom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "storeRoom", $$v);
      },
      expression: "form.storeRoom"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-terrace",
      "label": _vm.$t('Terrace'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.terrace,
      callback: function ($$v) {
        _vm.$set(_vm.form, "terrace", $$v);
      },
      expression: "form.terrace"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-showcase-windows",
      "label": _vm.$t('ShowCaseWindows'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.showCaseWindows,
      callback: function ($$v) {
        _vm.$set(_vm.form, "showCaseWindows", $$v);
      },
      expression: "form.showCaseWindows"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-street-entrance",
      "label": _vm.$t('StreetEntrance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.streetEntrance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "streetEntrance", $$v);
      },
      expression: "form.streetEntrance"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticClass: "trestle",
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-trestle",
      "label": _vm.$t('Trestle'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.trestle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "trestle", $$v);
      },
      expression: "form.trestle"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticClass: "goodslift",
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-goods-lift",
      "label": _vm.$t('GoodsLift'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.goodsLift,
      callback: function ($$v) {
        _vm.$set(_vm.form, "goodsLift", $$v);
      },
      expression: "form.goodsLift"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-tv",
      "label": 'TV',
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.tv,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tv", $$v);
      },
      expression: "form.tv"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-basement",
      "label": _vm.$t('BasementFloor'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.basementFloor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "basementFloor", $$v);
      },
      expression: "form.basementFloor"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "is-rooftop-apartment",
      "label": _vm.$t('RoofTopApartment'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.rooftopApartment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "rooftopApartment", $$v);
      },
      expression: "form.rooftopApartment"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-separate-entrance",
      "label": _vm.$t('SeparateEntrance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.separateEntrance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "separateEntrance", $$v);
      },
      expression: "form.separateEntrance"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-closed-yard",
      "label": _vm.$t('ClosedYard'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.closedYard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "closedYard", $$v);
      },
      expression: "form.closedYard"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' || _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticClass: "meetingRoom",
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-meeting-room",
      "label": _vm.$t('MeetingRoom'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meetingRoom,
      callback: function ($$v) {
        _vm.$set(_vm.form, "meetingRoom", $$v);
      },
      expression: "form.meetingRoom"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-industrial-power-supply",
      "label": _vm.$t('IndustrialPowerSupply'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.industrialPowerSupply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "industrialPowerSupply", $$v);
      },
      expression: "form.industrialPowerSupply"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-climate-device",
      "label": _vm.$t('ClimaticDevice'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.climaticDevice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "climaticDevice", $$v);
      },
      expression: "form.climaticDevice"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-ventilation",
      "label": _vm.$t('Ventilation'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.ventilation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ventilation", $$v);
      },
      expression: "form.ventilation"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-well",
      "label": _vm.$t('Well'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.well,
      callback: function ($$v) {
        _vm.$set(_vm.form, "well", $$v);
      },
      expression: "form.well"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-sewerage",
      "label": _vm.$t('Sewerage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sewerage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sewerage", $$v);
      },
      expression: "form.sewerage"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "gas-included",
      "label": _vm.$t('GasIncluded'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.gasIncluded,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gasIncluded", $$v);
      },
      expression: "form.gasIncluded"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' || _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-water",
      "label": _vm.$t('Water'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.water,
      callback: function ($$v) {
        _vm.$set(_vm.form, "water", $$v);
      },
      expression: "form.water"
    }
  }) : _vm._e(), _vm.form.estateType == 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-road",
      "label": _vm.$t('Road'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.road,
      callback: function ($$v) {
        _vm.$set(_vm.form, "road", $$v);
      },
      expression: "form.road"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-public-transport",
      "label": _vm.$t('PublicTransport'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.publicTransport,
      callback: function ($$v) {
        _vm.$set(_vm.form, "publicTransport", $$v);
      },
      expression: "form.publicTransport"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'sanitary' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticClass: "additional-first-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "hot-water",
      "label": _vm.$t('HotWater'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.hotWater,
      callback: function ($$v) {
        _vm.$set(_vm.form, "hotWater", $$v);
      },
      expression: "form.hotWater"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "bathroom-inside",
      "label": _vm.$t('BathroomInside'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.bathroomInside,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bathroomInside", $$v);
      },
      expression: "form.bathroomInside"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "bathroom-types",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.bathroomTypes,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('BathroomType')
    },
    model: {
      value: _vm.form.bathroomType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bathroomType", $$v);
      },
      expression: "form.bathroomType"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-washing-machine",
      "label": _vm.$t('WashingMachine'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.washingMachine,
      callback: function ($$v) {
        _vm.$set(_vm.form, "washingMachine", $$v);
      },
      expression: "form.washingMachine"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-pool",
      "label": _vm.$t('Pool'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.pool,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pool", $$v);
      },
      expression: "form.pool"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    attrs: {
      "id": "has-pool",
      "label": _vm.$t('Pool'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.pool,
      callback: function ($$v) {
        _vm.$set(_vm.form, "pool", $$v);
      },
      expression: "form.pool"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-boiler",
      "label": _vm.$t('Boiler'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.boiler,
      callback: function ($$v) {
        _vm.$set(_vm.form, "boiler", $$v);
      },
      expression: "form.boiler"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-shower",
      "label": _vm.$t('Shower'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.shower,
      callback: function ($$v) {
        _vm.$set(_vm.form, "shower", $$v);
      },
      expression: "form.shower"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-local-sewerage",
      "label": _vm.$t('LocalSewerage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.localSewerage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "localSewerage", $$v);
      },
      expression: "form.localSewerage"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-central-sewerage",
      "label": _vm.$t('CentralSewerage'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralSewerage,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralSewerage", $$v);
      },
      expression: "form.centralSewerage"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "additional-second-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    attrs: {
      "id": "has-local-water-supply",
      "label": _vm.$t('LocalWaterSupply'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.localWaterSupply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "localWaterSupply", $$v);
      },
      expression: "form.localWaterSupply"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-central-water-supply",
      "label": _vm.$t('CentralWaterSupply'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralWaterSupply,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralWaterSupply", $$v);
      },
      expression: "form.centralWaterSupply"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    attrs: {
      "id": "has-new-plumbing",
      "label": _vm.$t('NewPlumbing'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.piping,
      callback: function ($$v) {
        _vm.$set(_vm.form, "piping", $$v);
      },
      expression: "form.piping"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-bath",
      "label": _vm.$t('Bath'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.bath,
      callback: function ($$v) {
        _vm.$set(_vm.form, "bath", $$v);
      },
      expression: "form.bath"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-sauna",
      "label": _vm.$t('Sauna'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sauna,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sauna", $$v);
      },
      expression: "form.sauna"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-separate-toilet-and-bath",
      "label": _vm.$t('SeparateToiletAndBath'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.wcBath,
      callback: function ($$v) {
        _vm.$set(_vm.form, "wcBath", $$v);
      },
      expression: "form.wcBath"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'surroundings' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "gap": "32px"
    }
  }, [_c('v-col', {
    staticStyle: {
      "padding": "32px 0 0 0",
      "max-width": "432px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType === 'commercial' || _vm.form.estateType === 'land' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "idea-location",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.ideaLocations,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('IdeaLocation')
    },
    model: {
      value: _vm.form.ideaLocation,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ideaLocation", $$v);
      },
      expression: "form.ideaLocation"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "access-type",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.accessTypes,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('AccessType')
    },
    model: {
      value: _vm.form.accessType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accessType", $$v);
      },
      expression: "form.accessType"
    }
  })], 1) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "street-lighting",
      "label": _vm.$t('StreetLighting'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.streetLighting,
      callback: function ($$v) {
        _vm.$set(_vm.form, "streetLighting", $$v);
      },
      expression: "form.streetLighting"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "sidewalk",
      "label": _vm.$t('Sidewalk'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.sidewalk,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sidewalk", $$v);
      },
      expression: "form.sidewalk"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "road-access",
      "label": _vm.$t('RoadAccess'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.roadAccess,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roadAccess", $$v);
      },
      expression: "form.roadAccess"
    }
  }) : _vm._e(), _vm.form.estateType === 'land' ? _c('v-row', [_c('BaseInput', {
    attrs: {
      "id": "nearest-location-km",
      "placeholder": _vm.$t('NearestLocationKm'),
      "type": "number"
    },
    model: {
      value: _vm.form.nearestLocationKm,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nearestLocationKm", $$v);
      },
      expression: "form.nearestLocationKm"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'garage' ? _c('v-select', {
    staticStyle: {
      "max-width": "432px",
      "margin-bottom": "-12px !important"
    },
    attrs: {
      "clearable": "",
      "items": _vm.reservois,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('BodyOfWater')
    },
    model: {
      value: _vm.form.reservoir,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reservoir", $$v);
      },
      expression: "form.reservoir"
    }
  }) : _vm._e(), _vm.form.estateType !== 'garage' ? _c('v-row', [_c('BaseInput', {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      "id": "reservoir-name",
      "placeholder": _vm.$t('BodyOfWaterName')
    },
    model: {
      value: _vm.form.reservoirName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reservoirName", $$v);
      },
      expression: "form.reservoirName"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "margin-top": "0px"
    },
    attrs: {
      "id": "reservoir-distance",
      "type": "number",
      "placeholder": _vm.$t('BodyOfWaterDistance') + ' (m)'
    },
    model: {
      value: _vm.form.reservoirDistance,
      callback: function ($$v) {
        _vm.$set(_vm.form, "reservoirDistance", $$v);
      },
      expression: "form.reservoirDistance"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'garage' && _vm.form.estateType !== 'apartment' ? _c('v-row', {
    staticStyle: {
      "margin-top": "12px",
      "margin-bottom": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": "coastLine-length",
      "type": "number",
      "placeholder": _vm.$t('CoastLineLength')
    },
    model: {
      value: _vm.form.coastLineLength,
      callback: function ($$v) {
        _vm.$set(_vm.form, "coastLineLength", $$v);
      },
      expression: "form.coastLineLength"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-select', {
    staticStyle: {
      "margin-top": "12px !important",
      "max-width": "432px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.neighbours,
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('Neighbours')
    },
    model: {
      value: _vm.form.neighbours,
      callback: function ($$v) {
        _vm.$set(_vm.form, "neighbours", $$v);
      },
      expression: "form.neighbours"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticStyle: {
      "padding": "32px 0 0 0",
      "max-width": "432px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'garage' ? _c('v-select', {
    staticStyle: {
      "margin-bottom": "-12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.buildings,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Building1')
    },
    model: {
      value: _vm.form.building,
      callback: function ($$v) {
        _vm.$set(_vm.form, "building", $$v);
      },
      expression: "form.building"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' ? _c('v-select', {
    staticStyle: {
      "margin-bottom": "-12px"
    },
    attrs: {
      "clearable": "",
      "items": _vm.roads,
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('Roads')
    },
    model: {
      value: _vm.form.roads,
      callback: function ($$v) {
        _vm.$set(_vm.form, "roads", $$v);
      },
      expression: "form.roads"
    }
  }) : _vm._e(), _vm.form.estateType != 'garage' ? _c('v-select', {
    attrs: {
      "clearable": "",
      "items": _vm.locations,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('Location')
    },
    model: {
      value: _vm.form.location,
      callback: function ($$v) {
        _vm.$set(_vm.form, "location", $$v);
      },
      expression: "form.location"
    }
  }) : _vm._e(), _vm.form.estateType != 'commercial' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "margin-top": "-8px !important"
    },
    attrs: {
      "id": "is-forest-near",
      "label": _vm.$t('ForestIsNear'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.forestIsNear,
      callback: function ($$v) {
        _vm.$set(_vm.form, "forestIsNear", $$v);
      },
      expression: "form.forestIsNear"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'communication' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticStyle: {
      "padding-top": "32px",
      "padding-bottom": "12px",
      "padding-left": "0px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType === 'storage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "access-24h",
      "label": _vm.$t('Access24h'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.access24h,
      callback: function ($$v) {
        _vm.$set(_vm.form, "access24h", $$v);
      },
      expression: "form.access24h"
    }
  }) : _vm._e(), _vm.form.estateType === 'storage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "security-24h",
      "label": _vm.$t('Security24h'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.security24h,
      callback: function ($$v) {
        _vm.$set(_vm.form, "security24h", $$v);
      },
      expression: "form.security24h"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "access-control",
      "label": _vm.$t('AccessControl'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.accessControl,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accessControl", $$v);
      },
      expression: "form.accessControl"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' || _vm.form.estateType === 'building' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "security-personnel",
      "label": _vm.$t('SecurityPersonnel'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.securityPersonnel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "securityPersonnel", $$v);
      },
      expression: "form.securityPersonnel"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' || _vm.form.estateType === 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "security-camera",
      "label": _vm.$t('SecurityCamera'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.securityCamera,
      callback: function ($$v) {
        _vm.$set(_vm.form, "securityCamera", $$v);
      },
      expression: "form.securityCamera"
    }
  }) : _vm._e(), _vm.form.estateType === 'garage' || _vm.form.estateType === 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "security-alarm",
      "label": _vm.$t('SecurityAlarm'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.securityAlarm,
      callback: function ($$v) {
        _vm.$set(_vm.form, "securityAlarm", $$v);
      },
      expression: "form.securityAlarm"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-select', {
    attrs: {
      "clearable": "",
      "items": _vm.securitySystems,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('SelectSecuritySystem')
    },
    model: {
      value: _vm.form.securitySystem,
      callback: function ($$v) {
        _vm.$set(_vm.form, "securitySystem", $$v);
      },
      expression: "form.securitySystem"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-internet",
      "label": _vm.$t('Internet'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.internet,
      callback: function ($$v) {
        _vm.$set(_vm.form, "internet", $$v);
      },
      expression: "form.internet"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-phone",
      "label": _vm.$t('Phone'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.telephone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "telephone", $$v);
      },
      expression: "form.telephone"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-cable-tv",
      "label": _vm.$t('CableTv'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.cableTv,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cableTv", $$v);
      },
      expression: "form.cableTv"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-security-door",
      "label": _vm.$t('SecurityDoor'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.securityDoor,
      callback: function ($$v) {
        _vm.$set(_vm.form, "securityDoor", $$v);
      },
      expression: "form.securityDoor"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticStyle: {
      "padding-top": "44px",
      "padding-bottom": "0px",
      "padding-left": "20px",
      "padding-right": "0px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "is-staircase-locked",
      "label": _vm.$t('LockedStairway'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.staircaseLocked,
      callback: function ($$v) {
        _vm.$set(_vm.form, "staircaseLocked", $$v);
      },
      expression: "form.staircaseLocked"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-neighbourhood-watch",
      "label": _vm.$t('NeighbourhoodWatch'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.neighbourhoodSecurity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "neighbourhoodSecurity", $$v);
      },
      expression: "form.neighbourhoodSecurity"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-guard",
      "label": _vm.$t('Guard'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.guard,
      callback: function ($$v) {
        _vm.$set(_vm.form, "guard", $$v);
      },
      expression: "form.guard"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-video-surveillance",
      "label": _vm.$t('VideoSurveillance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.videoSecurity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "videoSecurity", $$v);
      },
      expression: "form.videoSecurity"
    }
  }) : _vm._e(), _vm.form.estateType == 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-video-surveillance",
      "label": _vm.$t('VideoSurveillance'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.videoSecurity,
      callback: function ($$v) {
        _vm.$set(_vm.form, "videoSecurity", $$v);
      },
      expression: "form.videoSecurity"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-fence",
      "label": _vm.$t('Fence'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fence,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fence", $$v);
      },
      expression: "form.fence"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'heating' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticClass: "additional-first-row",
    staticStyle: {
      "padding-bottom": "12px"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType === 'office' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "id": "heating",
      "label": _vm.$t('Heating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.heating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "heating", $$v);
      },
      expression: "form.heating"
    }
  }) : _vm._e(), _vm.form.estateType === 'office' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "conditioned-air-type",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.conditionedAirTypes,
      "item-text": "name",
      "item-value": "value",
      "outlined": "",
      "label": _vm.$t('ConditionedAirType')
    },
    model: {
      value: _vm.form.conditionedAirType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "conditionedAirType", $$v);
      },
      expression: "form.conditionedAirType"
    }
  })], 1) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-fireplace",
      "label": _vm.$t('Fireplace'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.firePlace,
      callback: function ($$v) {
        _vm.$set(_vm.form, "firePlace", $$v);
      },
      expression: "form.firePlace"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-central-gas",
      "label": _vm.$t('CentralGas'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralGas,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralGas", $$v);
      },
      expression: "form.centralGas"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-central-heating",
      "label": _vm.$t('CentralHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralHeating", $$v);
      },
      expression: "form.centralHeating"
    }
  }) : _vm._e(), _vm.form.estateType == 'garage' ? _c('v-checkbox', {
    attrs: {
      "id": "has-central-heating",
      "label": _vm.$t('CentralHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.centralHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "centralHeating", $$v);
      },
      expression: "form.centralHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-gas-heating",
      "label": _vm.$t('GasHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.gasHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gasHeating", $$v);
      },
      expression: "form.gasHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-furnace-heating",
      "label": _vm.$t('FurnaceHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.furnaceHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "furnaceHeating", $$v);
      },
      expression: "form.furnaceHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-electrical-heating",
      "label": _vm.$t('ElectricalHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.electricalHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "electricalHeating", $$v);
      },
      expression: "form.electricalHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-solid-fuel",
      "label": _vm.$t('SolidFuel'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.solidFuel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "solidFuel", $$v);
      },
      expression: "form.solidFuel"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "additional-second-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType != 'land' ? _c('v-checkbox', {
    attrs: {
      "id": "has-combined-heating",
      "label": _vm.$t('CombinedHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.combinedHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "combinedHeating", $$v);
      },
      expression: "form.combinedHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-geothermal-heating",
      "label": _vm.$t('GeothermalHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.geothermalHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "geothermalHeating", $$v);
      },
      expression: "form.geothermalHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-air-conditioner",
      "label": _vm.$t('AirConditioner'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.conditioner,
      callback: function ($$v) {
        _vm.$set(_vm.form, "conditioner", $$v);
      },
      expression: "form.conditioner"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'commercial' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-floor-heating",
      "label": _vm.$t('FloorHeating'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.floorHeating,
      callback: function ($$v) {
        _vm.$set(_vm.form, "floorHeating", $$v);
      },
      expression: "form.floorHeating"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-air-heat-pump",
      "label": _vm.$t('AirHeatPump'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.airHeatPump,
      callback: function ($$v) {
        _vm.$set(_vm.form, "airHeatPump", $$v);
      },
      expression: "form.airHeatPump"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-air-water-pump",
      "label": _vm.$t('AirWaterHeatPump'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.airWaterPump,
      callback: function ($$v) {
        _vm.$set(_vm.form, "airWaterPump", $$v);
      },
      expression: "form.airWaterPump"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'apartment' && _vm.form.estateType != 'commercial' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-liquid-fuel",
      "label": _vm.$t('LiquidFuel'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.liquidFuel,
      callback: function ($$v) {
        _vm.$set(_vm.form, "liquidFuel", $$v);
      },
      expression: "form.liquidFuel"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _vm.form.sectionType == 'kitchen' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-col', {
    staticClass: "additional-first-row",
    staticStyle: {
      "padding-bottom": "12px !important"
    },
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType === 'apartment' || _vm.form.estateType === 'house' || _vm.form.estateType === 'cottage' ? _c('v-row', [_c('v-select', {
    staticClass: "no-active",
    staticStyle: {
      "margin-bottom": "12px"
    },
    attrs: {
      "id": "equipment",
      "clearable": "",
      "clear-icon": "mdi-close",
      "items": _vm.equipments,
      "item-text": "name",
      "item-value": "name",
      "outlined": "",
      "label": _vm.$t('Equipment')
    },
    model: {
      value: _vm.form.equipment,
      callback: function ($$v) {
        _vm.$set(_vm.form, "equipment", $$v);
      },
      expression: "form.equipment"
    }
  })], 1) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "12px",
      "margin-top": "-2px !important"
    },
    attrs: {
      "id": "has-electrical-stove",
      "label": _vm.$t('ElectricalStove'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.electricalStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "electricalStove", $$v);
      },
      expression: "form.electricalStove"
    }
  }) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px",
      "margin-top": "-2px !important"
    },
    attrs: {
      "id": "has-gas-stove",
      "label": _vm.$t('GasStove'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.gasStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gasStove", $$v);
      },
      expression: "form.gasStove"
    }
  }) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px",
      "margin-top": "-2px !important"
    },
    attrs: {
      "id": "has-ceramic-stove",
      "label": _vm.$t('CeramicStove'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.ceramicStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "ceramicStove", $$v);
      },
      expression: "form.ceramicStove"
    }
  }) : _vm._e(), _vm.form.estateType == 'apartment' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px",
      "margin-top": "-2px !important"
    },
    attrs: {
      "id": "has-open-kitchen",
      "label": _vm.$t('OpenKitchen'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.openedKitchen,
      callback: function ($$v) {
        _vm.$set(_vm.form, "openedKitchen", $$v);
      },
      expression: "form.openedKitchen"
    }
  }) : _vm._e(), _vm.form.estateType == 'commercial' ? _c('v-checkbox', {
    attrs: {
      "id": "has-kitchen",
      "label": _vm.$t('Kitchen'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.kitchen,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kitchen", $$v);
      },
      expression: "form.kitchen"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'house' && _vm.form.estateType != 'house_part' && _vm.form.estateType != 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-fridge",
      "label": _vm.$t('Fridge'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fridge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fridge", $$v);
      },
      expression: "form.fridge"
    }
  }) : _vm._e(), _vm.form.estateType == 'house' || _vm.form.estateType == 'house_part' || _vm.form.estateType == 'cottage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-fridge",
      "label": _vm.$t('Fridge'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fridge,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fridge", $$v);
      },
      expression: "form.fridge"
    }
  }) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "26px"
    },
    attrs: {
      "id": "has-kitchen-furniture",
      "label": _vm.$t('KitchenFurniture'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.kitchenFurniture,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kitchenFurniture", $$v);
      },
      expression: "form.kitchenFurniture"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "additional-second-row",
    attrs: {
      "cols": "6"
    }
  }, [_vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "0px"
    },
    attrs: {
      "id": "has-firewood-stove",
      "label": _vm.$t('FireWoodStove'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.fireWoodStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "fireWoodStove", $$v);
      },
      expression: "form.fireWoodStove"
    }
  }) : _vm._e(), _vm.form.estateType !== 'land' && _vm.form.estateType !== 'commercial' && _vm.form.estateType !== 'garage' ? _c('v-checkbox', {
    staticStyle: {
      "padding-top": "22px"
    },
    attrs: {
      "id": "has-induction-stove",
      "label": _vm.$t('InductionHob'),
      "color": "black",
      "hide-details": ""
    },
    model: {
      value: _vm.form.inductionStove,
      callback: function ($$v) {
        _vm.$set(_vm.form, "inductionStove", $$v);
      },
      expression: "form.inductionStove"
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("5")]), _c('h5', [_vm._v(_vm._s(_vm.$t("LanguageSpecificInfo")))])]), _c('v-row', {
    staticClass: "box-wrapper",
    staticStyle: {
      "padding-top": "20px"
    }
  }, _vm._l(_vm.languageTypes, function (languageType) {
    return _c('button', {
      key: languageType.name,
      class: {
        'big-box1': !languageType.isChosen,
        'big-box1-white active-box': languageType.isChosen
      },
      attrs: {
        "item-text": "name",
        "item-value": "value"
      },
      on: {
        "click": function ($event) {
          _vm.form.languageType = languageType.value;
          _vm.chooseBox3(languageType.value);
        }
      }
    }, [_c('v-row', {
      staticStyle: {
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(languageType.name) + " ")])], 1);
  }), 0), !_vm.form.languageType || _vm.form.languageType === 'english' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link"
    },
    model: {
      value: _vm.form.sloganENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganENG", $$v);
      },
      expression: "form.sloganENG"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ObjectDescription")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionENG,
      expression: "form.descriptionENG"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionENG
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionENG", $event.target.value);
      }
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-eng",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLinkENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLinkENG", $$v);
      },
      expression: "form.additionalInfoLinkENG"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-eng",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitleENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitleENG", $$v);
      },
      expression: "form.linkTitleENG"
    }
  })], 1), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "services-in-neighbourhood-eng"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodENG,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodENG", $$v);
      },
      expression: "form.servicesInNeighbourhoodENG"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.form.languageType === 'russian' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.sloganRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganRU", $$v);
      },
      expression: "form.sloganRU"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ObjectDescription")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionRU,
      expression: "form.descriptionRU"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionRU
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionRU", $event.target.value);
      }
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-ru",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLinkRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLinkRU", $$v);
      },
      expression: "form.additionalInfoLinkRU"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-ru",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitleRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitleRU", $$v);
      },
      expression: "form.linkTitleRU"
    }
  })], 1), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodRU,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodRU", $$v);
      },
      expression: "form.servicesInNeighbourhoodRU"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.form.languageType === 'italy' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Slogan")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.sloganIT,
      callback: function ($$v) {
        _vm.$set(_vm.form, "sloganIT", $$v);
      },
      expression: "form.sloganIT"
    }
  })], 1), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ObjectDescription")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.descriptionIT,
      expression: "form.descriptionIT"
    }],
    attrs: {
      "id": "textarea",
      "name": "textarea"
    },
    domProps: {
      "value": _vm.form.descriptionIT
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "descriptionIT", $event.target.value);
      }
    }
  })]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-link-ru",
      "placeholder": _vm.$t('AdditionalInfoLink')
    },
    model: {
      value: _vm.form.additionalInfoLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "additionalInfoLink", $$v);
      },
      expression: "form.additionalInfoLink"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "width": "444px",
      "max-width": "unset"
    },
    attrs: {
      "id": "link-title-ru",
      "placeholder": _vm.$t('LinkTitle')
    },
    model: {
      value: _vm.form.linkTitle,
      callback: function ($$v) {
        _vm.$set(_vm.form, "linkTitle", $$v);
      },
      expression: "form.linkTitle"
    }
  })], 1), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "32px !important"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("ServicesInNeighbourhood")))])]) : _vm._e(), _vm.form.estateType != 'land' && _vm.form.estateType != 'garage' && _vm.form.estateType != 'commercial' ? _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset"
    },
    attrs: {
      "id": "additional-info-ru"
    },
    model: {
      value: _vm.form.servicesInNeighbourhoodIT,
      callback: function ($$v) {
        _vm.$set(_vm.form, "servicesInNeighbourhoodIT", $$v);
      },
      expression: "form.servicesInNeighbourhoodIT"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1), _c('div', {
    staticClass: "section"
  }, [_c('v-row', [_c('div', {
    staticClass: "circle-number"
  }, [_vm._v("6")]), _c('h5', [_vm._v(_vm._s(_vm.$t("Media")))])]), _c('v-row', {
    staticClass: "subheading"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Pictures")))])]), _c('v-row', {
    staticStyle: {
      "padding-top": "4px",
      "width": "100%"
    }
  }, [!_vm.edit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs": [],
      "is-visible": true,
      "clearAll": _vm.$t('ClearAll')
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e(), _vm.edit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs2": _vm.form.images,
      "is-visible": true,
      "clearAll": _vm.$t('ClearAll')
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e()], 1), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ApplyWatermarkToImages')
    },
    on: {
      "click": function ($event) {
        return _vm.changeWatermarkStatus($event);
      }
    },
    model: {
      value: _vm.form.isWatermarkApplied,
      callback: function ($$v) {
        _vm.$set(_vm.form, "isWatermarkApplied", $$v);
      },
      expression: "form.isWatermarkApplied"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset",
      "width": "100%"
    },
    attrs: {
      "id": "video-link",
      "placeholder": _vm.$t('VideoLink')
    },
    model: {
      value: _vm.form.videoLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "videoLink", $$v);
      },
      expression: "form.videoLink"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('BaseInput', {
    staticStyle: {
      "max-width": "unset",
      "width": "100%"
    },
    attrs: {
      "id": "Virtual-tour-link",
      "placeholder": _vm.$t('VirtualTourLink')
    },
    model: {
      value: _vm.form.virtualTourLink,
      callback: function ($$v) {
        _vm.$set(_vm.form, "virtualTourLink", $$v);
      },
      expression: "form.virtualTourLink"
    }
  })], 1)], 1), _c('div', {
    class: {
      'buttons-row justify-end': !_vm.edit,
      'buttons-row justify-between': _vm.edit
    }
  }, [_vm.edit ? _c('div', [this.form.statusCode === 1 ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "id": "listing-delete",
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.openDeleteModal
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), this.form.status === 'archived' ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.openDeletePermanentlyModal
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("DeletePermanently")) + " ")]) : _vm._e()]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('button', {
    staticClass: "cancel padding-top",
    staticStyle: {
      "padding-left": "12px",
      "padding-right": "12px"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.back();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]), _c('div', {
    staticClass: "cancel",
    staticStyle: {
      "margin-right": "12px",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, false);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("Save")))]) : _vm._e()]), _c('div', {
    staticClass: "add-button",
    attrs: {
      "id": "save-button"
    },
    on: {
      "click": function ($event) {
        return _vm.saveListing(_vm.form, true);
      }
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('img', {
    staticStyle: {
      "margin-right": "9px"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/checkmarkCircled.svg")
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', [_vm._v(_vm._s(_vm.$t("SaveAndExit")))]) : _vm._e()])])])]) : _vm._e()]), _vm.deleteModalOpen ? _c('DeleteModal', {
    staticStyle: {
      "z-index": "121"
    },
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.deleteModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteListing();
      }
    }
  }) : _vm._e(), _vm.deletePermanentlyModalOpen ? _c('DeleteModal', {
    staticStyle: {
      "z-index": "121"
    },
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.deletePermanentlyModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteListingPermanently();
      }
    }
  }) : _vm._e(), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "position": _vm.position,
      "listing": _vm.form,
      "multi": false
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeListingStatus,
      "open-booking-modal": _vm.openBookingDetailsModal
    }
  }) : _vm._e(), _vm.bookingDetailsModalOpen ? _c('BookingDetailsModal', {
    attrs: {
      "opened-listing": _vm.bookedListing
    },
    on: {
      "update-listing": _vm.changeListingStatus,
      "close-modal": function ($event) {
        _vm.bookingDetailsModalOpen = false;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }