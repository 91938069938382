<template>
  <div
    @click="closePopover($event)"
    id="object-selection-overlay"
    class="overlay"
  >
    <div id="popover" class="card-details">
      <div class="object-rows">
        <div
          @click="selectObject(object)"
          v-for="object in project.objects"
          :key="object._id"
          class="object-row"
        >
          <p class="content-small">{{ object.address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ObjectSelection",
  props: ["project", "position"],
  data: () => ({}),
  created() {},
  mounted() {
    let topPosition = this.position.top;
    let rightPosition = this.position.right;
    document
      .getElementById("popover")
      .style.setProperty("top", topPosition + "px");
    document
      .getElementById("popover")
      .style.setProperty("right", rightPosition + "px");
  },
  computed: {},

  methods: {
    closePopover(e) {
      let popover = document.getElementById("object-selection-overlay");
      if (e.target === popover) {
        this.$emit("closed");
      }
    },
    selectObject(object) {
      object.apartmentNumber = this.project.apartmentNumber;
      this.$emit("objectSelected", object);
    },
  },
};
</script>
<style lang="scss" scoped>
#popover {
  position: absolute;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  max-height: 256px;
  overflow-y: scroll;
  /* white */
  width: 408px;

  background: #ffffff;
  /* shadow1 */

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.object-rows {
}
.object-row {
  display: flex;
  gap: 12px;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  background: #f4f5f7;
  border-radius: 8px;
  margin: 4px 0px;
  cursor: pointer;
  &:hover {
    background: #e7e7e7;
  }
  img {
    cursor: pointer;
  }
}
</style>
