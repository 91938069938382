<template>
  <div class="main" @focusin="isOpen = true">
    <v-text-field
      autocomplete="off"
      v-model="search"
      :placeholder="placeholder"
      :error="errorKey"
      outlined
      @click="resetPage"
      @focus="onFocus"
      @blur="resetPage"
      clearable
      hide-details
      style="background: white"
    >
    </v-text-field>  
    <div v-if="isOpen" class="items">
      <div class="item-wrapper" ref="scrollContainer">
        <div v-for="(item, index) in foundItems" :key="index" class="item"
          @mousedown.prevent="chooseResult(item[displayKey], item)" :id="'item' + item._id">
          {{ item[displayKey] }}
        </div>
        <div style="width: 100%; height: 80px; display: flex; justify-content: center; align-items: center" v-if="isFetching">
          <div class="loader"></div>
        </div>
      </div>
      <div class="nothing-found" v-if="search && foundItems.length === 0 && !searchInProgress">
        <div class="item no-hover">{{ $t("NoResultsFound") }}"{{ search }}"</div>
      </div>      
      <div class="separator"></div>
      <div class="add-new"  @mousedown.prevent="addNew">
        <img src="../../assets/images/plusSign.svg" alt="" />
        {{ $t("AddNew") }}
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Autocomplete",
  props: [
    "searching",
    "closeMenu",
    "writtenText",
    "items",
    "searchKey",
    "displayKey",
    "placeholder",
    "errorKey",
    "chosenObject",
    "itemsPerPage"
  ],
  data: () => ({
    search: "",
    isOpen: false,
    foundItems: [],
    page: 1,
    isFetching: false,
    searchInProgress: false,
    totalPages: null,
    clearingSelection: false,
    skipFocus: false,
    debounceTimeout: null,
  }),
  created() {
    if (this.writtenText) {
      this.search = this.writtenText;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
    });
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    search(val) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.page = 1;
        this.foundItems = [];
        this.findItems(val).then(() => {
          this.isOpen = !!val || this.foundItems.length > 0;
        });
      }, 500);
    },
    items() {
      this.foundItems = this.items;
    },
    closeMenu() {
      this.isOpen = false;
    },
    isOpen(newVal) {
      this.$nextTick(() => {
        if(newVal) {
          this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll);
        } else {
          this.$refs.scrollContainer.removeEventListener('scroll', this.handleScroll);
          this.resetPage();
        }
      });
    },
  },
  methods: {
    clearSelection() {
      this.skipFocus = true;
      this.search = "";
      this.page = 1;
      this.$emit("removeResult", this.chosenObject);
      this.isOpen = true;
    },
    resetPage() {
      this.page = 1;
    },
    async findItems(val) {
    if (this.skipFocus) {
      this.skipFocus = false;
      return;
    }
    this.searchInProgress = true; 
    try {
        if (!this.searching) {
          this.foundItems = this.items.filter(item =>
            item[this.searchKey].toLowerCase().includes(val.toLowerCase())
          );
        } else {
          this.isFetching = true;
          this.loadingPage = this.page;
          const response = await axios.get(`/api/fetch-projects/${val ? encodeURIComponent(val) : ''}`, {
            params: {
              limit: this.itemsPerPage,
              page: this.page
            }
          });
          this.totalPages = response.data.totalPages;
          if (this.page === this.loadingPage) {
            if (this.page === 1) {
              this.foundItems = response.data.deals;
            } else {
              this.foundItems.push(...response.data.deals);
            }
          }
          this.isFetching = false;
        }
        this.searchInProgress = false;
      } catch(error) {
        console.error(error);
      } finally {
        this.searchInProgress = false;
      }
    },
    onFocus() {
      this.findItems(this.search);
    },
    handleScroll(e) {
      if (this.isOpen) {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (!this.isFetching && scrollTop + clientHeight >= scrollHeight) {
          if (this.page < this.totalPages && this.page === this.loadingPage) {
            this.page += 1;
            this.findItems(this.search);
          }
        }
      }
    },
    addNew() {
      this.$emit("addNew");
      this.isOpen = false;
    },
    async chooseResult(visualText, fullObject) {
      this.search = visualText;
      await this.$emit("resultChosen", fullObject);
      this.isOpen = false;
    },
  },
};
</script>


<style lang="scss" scoped>
.main {
  position: relative;
  width: 100%;
}
.items {
  box-sizing: border-box;
  //border: 1px solid #e6e8ec;
  margin-top: 8px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 111;
  position: absolute;
  background-color: white;
  width: 100%;
  border-radius: 8px;
}
.item-wrapper {
  max-height: 180px;
  overflow: auto;  
}
.item {
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;

  &:hover {
    background-color: #f4f5f7;
    cursor: pointer;
  }
}
.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}
.add-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  padding: 16px 12px 16px 12px;
  background-color: white;
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
}
.no-hover {
  cursor: default !important;
  background-color: white !important;
}
</style>
